import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { PostCallFormRef } from '../../../components/common/PostCallForm';
import { ActiveCall, CallRequest, useAcceptBackupConnectionMutation } from '../../../hooks/useCalls';
import { PostCallListItem } from '../../../hooks/usePostCall';

import { CallCardWrapper } from '../../../components/mwa-3.5-redesign/call-card';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { SchedulingModal } from '../../../components/scheduling-modal/scheduling-modal';
import { PostCallModal } from '../../../components/PostCallModal';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { EventLocations } from '../../../hooks/useEvent';

export enum CallVariant {
  ACTIVE = 'active',
  BACKUP = 'backup',
  COMPLETED = 'completed',
  INITIALIZING = 'initializing',
  MISSED = 'missed',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  UNAVAILABLE = 'unavailable',
  UPCOMING = 'upcoming',
}

interface CallConnectionsProps {
  callRequests: CallRequest[] | undefined;
  incompletePostCalls: PostCallListItem[] | undefined;
  activeCalls: ActiveCall[] | undefined;
}

export interface ScheduleModalData {
  isNow: boolean;
  listenerId: number;
  originalRequestId: number;
}

const CallConnections: React.FC<CallConnectionsProps> = ({ callRequests, incompletePostCalls, activeCalls }) => {
  const history = useHistory();
  const dfMdMedia = useMediaQuery('md');
  const callInEarlyFlag = useFeatureFlag('CALL_IN_EARLY');

  const [scheduleModal, setScheduleModal] = useState<ScheduleModalData>();
  const [postCallIdForPostCallModal, setPostCallIdForPostCallModal] = useState<number>();

  const acceptBackupConnection = useAcceptBackupConnectionMutation();

  const showRescheduleModal = (callData: CallRequest) => {
    setScheduleModal({
      isNow: callData.connections[0].listener_role.available_now,
      listenerId: callData.connections[0].listener_role.id,
      originalRequestId: callData.id,
    });
  };

  const now = new Date();
  const tenMinutesFromNow = new Date(now.getTime() + 10 * 60000);
  const fiveMinutesFromNow = new Date(now.getTime() + 5 * 60000);

  const postCallModalForm = useRef<PostCallFormRef>(null);

  // These are Now Call requests that haven't been accepted yet, or were passed but have a potential backup caller
  // (if there is another available listener and both the requested and back up listener have the tag "Team Listener")
  const pendingRequests = callRequests?.filter(
    (request) => request.connections[0].status === 'matched' || request.status === 'backup_pending',
  );

  // These are Now Call requests that have been passed on by the listener with no backup listener available
  // or scheduled calls that have been canceled by the listener
  const unavailableRequests = callRequests
    ?.filter((request) => request.connections[0].status === 'canceled' || request.connections[0].status === 'passed')
    .filter((request) => pendingRequests?.includes(request) === false);

  // These are requests that have been accepted by a backup listener but not yet confirmed by the caller
  let backupRequests = callRequests?.filter(
    (request) => request.connections[0].status === 'offered' && request.is_backup_request === true,
  );

  // These are call requests that are starting in the next 5 minutes (or 10 if Call In Early is enabled)
  const upcomingCalls = callRequests?.filter(
    (request) =>
      ['accepted', 'offered'].includes(request.connections[0].status) &&
      request.is_backup_request === false &&
      request.connections[0].scheduled_at &&
      new Date(request.connections[0].scheduled_at) <=
        (callInEarlyFlag.enabled ? tenMinutesFromNow : fiveMinutesFromNow),
  );

  // These are call requests that are NOT starting in the next 5 minutes (or 10 if Call In Early is enabled)
  const scheduledCalls = callRequests?.filter(
    (request) =>
      ['accepted', 'offered'].includes(request.connections[0].status) &&
      request.is_backup_request === false &&
      request.connections[0].scheduled_at &&
      new Date(request.connections[0].scheduled_at) >
        (callInEarlyFlag.enabled ? tenMinutesFromNow : fiveMinutesFromNow),
  );

  // These are active calls that both listener and caller have joined
  const inProgressCalls = activeCalls?.filter((call) => call.state.type === 'call_in_progress');
  // These are active calls that haven't been joined by both listener and caller
  const initializingCalls = activeCalls?.filter((call) => call.state.type !== 'call_in_progress');

  useEffect(() => {
    if (postCallIdForPostCallModal && !dfMdMedia) {
      sessionStorage.setItem(
        `postCallForm-${postCallIdForPostCallModal}`,
        JSON.stringify({
          step: postCallModalForm?.current?.step,
          ...postCallModalForm?.current?.getValues(),
        }),
      );
      history.push(`${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${postCallIdForPostCallModal}`);
    }
  }, [postCallIdForPostCallModal, dfMdMedia, history]);

  return (
    <>
      <div className="flex flex-col gap-4">
        {inProgressCalls?.map((activeCall) => (
          <CallCardWrapper
            variant={CallVariant.ACTIVE}
            activeCall={activeCall}
            buttonClick={() => window.open(`tel:${process.env.REACT_APP_TWILIO_PHONE_NUMBER}`, '_blank')}
          />
        ))}
        {/* Upcoming active calls 5 minutes out */}
        {initializingCalls?.map((initializingCall) => (
          <CallCardWrapper
            variant={CallVariant.INITIALIZING}
            activeCall={initializingCall}
            buttonClick={() => window.open(`tel:${process.env.REACT_APP_TWILIO_PHONE_NUMBER}`, '_blank')}
            fiveMinutesOut
            isNowCall={initializingCall.request.connections[0].scheduled_at === null}
          />
        ))}
        {backupRequests?.map((backupRequest) => (
          <CallCardWrapper
            variant={CallVariant.BACKUP}
            callRequest={backupRequest}
            buttonClick={() => acceptBackupConnection.mutate(backupRequest.connections[0].id)}
          />
        ))}
        {/* Scheduled upcoming calls */}
        {upcomingCalls?.map((upcomingCall) => (
          <CallCardWrapper
            variant={CallVariant.UPCOMING}
            callRequest={upcomingCall}
            buttonClick={() => window.open(`tel:${process.env.REACT_APP_TWILIO_PHONE_NUMBER}`, '_blank')}
          />
        ))}
        {pendingRequests?.map((pendingCall) => (
          <CallCardWrapper variant={CallVariant.PENDING} callRequest={pendingCall} />
        ))}
        {incompletePostCalls?.map((completedCall) => (
          <CallCardWrapper
            variant={CallVariant.COMPLETED}
            postCall={completedCall}
            buttonClick={() => {
              if (dfMdMedia) {
                console.log('open modal');
                setPostCallIdForPostCallModal(completedCall.id);
              } else {
                console.log('redirect');
                history.push(`${ROUTE_PATH.POSTCALLSEQUENCE}?postCallId=${completedCall.id}`);
              }
            }}
          />
        ))}
        {unavailableRequests?.map((unavailableRequest) => (
          <CallCardWrapper
            variant={CallVariant.UNAVAILABLE}
            callRequest={unavailableRequest}
            buttonClick={() => showRescheduleModal(unavailableRequest)}
          />
        ))}
        {/* We currently don't have a good way to display missed calls in a dismissable fashion,
        so will come in an upcoming ticket, but the variant is created here */}
        {/* <CallCardWrapper variant="missed" /> */}
        {scheduledCalls?.map((scheduledCall) => (
          <CallCardWrapper
            variant={CallVariant.SCHEDULED}
            callRequest={scheduledCall}
            buttonClick={() => showRescheduleModal(scheduledCall)}
          />
        ))}
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          listenerAvailableNow={scheduleModal.isNow}
          onExit={() => {
            setScheduleModal(undefined);
          }}
          originalRequestId={scheduleModal.originalRequestId}
          listenerId={scheduleModal.listenerId!}
          location={EventLocations.CALL_CARDS}
        />
      )}
      {postCallIdForPostCallModal && (
        <PostCallModal
          formRef={postCallModalForm}
          open={!!postCallIdForPostCallModal}
          onClose={() => setPostCallIdForPostCallModal(undefined)}
          postCallId={postCallIdForPostCallModal}
        />
      )}
    </>
  );
};

export default CallConnections;
