import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axiosGet, axiosGetV3, axiosPost } from '../api/axios-handler';
import Toast from '../components/common/PopUpMessage';
import { TagStatus } from './useCalls';
import { userQueryKeys } from './useUser';
import { EventTypes, analytics } from '@kindlyhuman/component-library';
import { PeerDetail } from './usePeers';
import useAuth from './useAuth';

export const experiencesQueryKeys = {
  recommended: ['recommendedExperiences'] as const,
  infiniteRecommended: ['infinitieExperiences'] as const,
  randomVideoExperiences: ['randomVideoExperiences'] as const,
};

export type PlaybackLocation = 'peer-profile' | 'search-results' | 'video-carousel';

export interface RecommendedExperienceResponse {
  count: number;
  data: Experience[];
}

export interface Experience {
  admin_summary: string;
  area_ids: number[];
  audio_url: string;
  background_tags: string[];
  client_id?: number;
  client_image_url?: string;
  duration: number;
  excerpt: string;
  id: number;
  is_available: boolean;
  // DEPRECATED, sort of, in favor of experience rating
  is_favorite: boolean;
  is_listener: boolean;
  listener_audio_id: number;
  listener_role_id: number;
  name: string;
  peer_type: string;
  profile_photo_square_file_url: string;
  rating: number;
  resource_client_id?: number;
  resource_id?: number;
  resource_image_url?: string;
  score: number;
  similarity_score: number;
  title: string;
  transcription: string;
  tag_names?: string[];
  video_id?: string;
}

export interface FavoriteExperience {
  created_at: string;
  duration: number;
  excerpt?: string;
  file_url: string;
  first_approved_at?: string;
  id: number;
  is_favorite: boolean;
  last_updated_at: string;
  listener_role: ListenerRole;
  listener_role_id: number;
  message_quality: number;
  title: string;
  topic_tags: FavoriteExperiencesTopicTag[];
}

export interface ListenerRole {
  about_me?: string;
  available: boolean;
  available_now: boolean;
  background_traits: any[];
  can_take_calls: boolean;
  connection_blocked: boolean;
  favorite_id?: number;
  id: number;
  is_active: boolean;
  is_peer_active: boolean;
  military_tag: any;
  next_available_text?: string;
  peer_status: string;
  primary_language_tag: string;
  profile_photo_file_url: string;
  profile_photo_square_file_url: string;
  profile_traits: string[];
  secondary_language_tag: string;
  share_link_url: string;
  spirituality_tag: string;
  state: string;
  status: string;
  tier_id: number;
  type: any;
  user: User;
}

export interface User {
  display_name: string;
  first_name: string;
  pronoun?: string | null;
}

export interface FavoriteExperiencesTopicTag {
  created_at: string;
  id: number;
  status: TagStatus;
  listener_audio_id: number;
  media: any;
  postcall_metadata_id: any;
  sort_order: number;
  tag: Tag;
  tag_id: number;
  user_id: any;
}

export interface Tag {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media?: Medum[];
  name: string;
  sort_order: number;
  tag_type: string;
}

export interface Medum {
  file_key: string;
  file_url: string;
}

const RECOMMENDED_EXPERIENCES_LIMIT = 12;
const getRecommendedExperiences = async ({
  tagGroupId,
  pageParam,
}: {
  tagGroupId?: number;
  pageParam: number;
}): Promise<InfiniteExperienceQueryResults> => {
  return await axiosGetV3('/listener_audio/recommended', {
    tag_group_ids: tagGroupId,
    page: pageParam,
    limit: RECOMMENDED_EXPERIENCES_LIMIT,
  }).then((response) => {
    return {
      count: response.data.count,
      data: response.data.data,
      nextPage: response.data.count >= pageParam * RECOMMENDED_EXPERIENCES_LIMIT ? pageParam + 1 : undefined,
    };
  });
};

// TODO figure out if this is still a thing in 3.5, if so we need to change the logic
// to get experiences with rating > 0
const getFavoriteExperiences = async (): Promise<FavoriteExperience[]> => {
  return await axiosGet('/listeners/audio?is_favorite=true', {}).then((response: { data: any }) => response.data);
};

const getExperienceByAvailablity = async (availableNow: boolean = true, limit: number, page: number) => {
  return await axiosGetV3('/listener_audio/available', {
    available_now: availableNow,
    limit: limit,
    page: page,
  }).then((response) => response.data);
};

export interface TextSearchResponse {
  caller_role_id: number;
  created_at: string;
  id: number;
  query: string;
}

export interface SearchFilterOptions {
  supportQuery?: string;
  limit?: number;
  page?: number;
  ageGroups?: string[];
  availableNow?: string[];
  backgroundTags?: number[];
  clientId?: number[];
  distinctListeners?: boolean;
  ethnicityTags?: number[];
  genderTags?: number[];
  peerType?: string[];
  resourceId?: number;
  createdAt?: Date;
}

const buildSearchFilterQueryParams = (searchFilterOptions: SearchFilterOptions): any => {
  let params: any = {};

  const mapOptionToParam = {
    query: searchFilterOptions.supportQuery || '',
    limit: searchFilterOptions.limit,
    page: searchFilterOptions.page,
    age_groups: searchFilterOptions.ageGroups?.length ? searchFilterOptions.ageGroups.join(',') : undefined,
    available_now: Array.isArray(searchFilterOptions.availableNow) ? searchFilterOptions.availableNow[0] : undefined,
    background_tags: searchFilterOptions.backgroundTags?.length
      ? searchFilterOptions.backgroundTags.join(',')
      : undefined,
    client_id: Array.isArray(searchFilterOptions.clientId) ? searchFilterOptions.clientId[0] : undefined,
    distinct_listeners: searchFilterOptions.distinctListeners,
    ethnicity_tags: searchFilterOptions.ethnicityTags?.length ? searchFilterOptions.ethnicityTags.join(',') : undefined,
    gender_tags: searchFilterOptions.genderTags?.length ? searchFilterOptions.genderTags.join(',') : undefined,
    peer_type: searchFilterOptions.peerType?.length ? searchFilterOptions.peerType.join(',') : undefined,
    resource_id: searchFilterOptions.resourceId || undefined,
  };

  Object.entries(mapOptionToParam).forEach(([key, value]) => {
    if (value !== undefined && value !== '') {
      params[key] = value;
    }
  });

  return params;
};

export const useRecommendedExperiencesTextSearch = ({
  enabled,
  onSuccess,
  searchFilterOptions,
}: {
  enabled: boolean;
  onSuccess?: () => void;
  searchFilterOptions: SearchFilterOptions;
}) => {
  // we won't send along a client Id if the user does not have client peers
  const { user } = useAuth();
  const queryClient = useQueryClient();

  if (!user?.has_peers) {
    searchFilterOptions.clientId = undefined;
  }

  const params = buildSearchFilterQueryParams(searchFilterOptions);

  return useQuery<RecommendedExperienceResponse>(
    ['textSearchExperiences', params],
    () => {
      analytics.trackEvent(EventTypes.SUBMIT_QUERY);
      return axiosGetV3('/listener_audio/search', { ...params }).then((response: { data: any }) => response.data);
    },
    {
      enabled,
      cacheTime: Infinity,
      staleTime: Infinity,
      // This is not ideal longterm, but it works for now. Returning a user's search_queries on the user object
      //  means we have to refetch the user object to update their "search" with the most recent query across pages.
      onSuccess: (_) => {
        onSuccess && onSuccess();
        queryClient.invalidateQueries(userQueryKeys.me);
      },

      onError: (err: any) => {
        // @ts-ignore
        Toast.error(err.response.data.description);
      },
    },
  );
};

export const useFavoriteExperiences = () =>
  useQuery<FavoriteExperience[]>(['favoriteExperiences'], () => getFavoriteExperiences());

export const useExperiences = (fetchData: boolean = true) => {
  const recommendedExperiences = useQuery<RecommendedExperienceResponse | null>(
    experiencesQueryKeys.recommended,
    () => (fetchData ? getRecommendedExperiences({ pageParam: 1 }) : Promise.resolve(null)),
    {
      staleTime: 1000 * 60 * 15,
      enabled: fetchData,
    },
  );

  return { recommendedExperiences };
};

const getRandomVideoExperiences = async () => {
  return await axiosGetV3('/listeners/audio', {
    limit: 4,
    experience_type: 'video',
    random: true,
    grade: 'approved',
  }).then((response) => response.data);
};

export const useVideoCarouselExperiences = () => {
  const { data, isLoading } = useQuery<Experience[]>(
    experiencesQueryKeys.randomVideoExperiences,
    () => getRandomVideoExperiences(),
    {
      staleTime: 1000 * 60 * 15,
    },
  );

  return { data, isLoading };
};

export const useExperienceByAvailablity = (availableNow: boolean = true, limit: number, page: number) => {
  return useQuery(['experiencesByAvailability', availableNow, limit, page], () =>
    getExperienceByAvailablity(availableNow, limit, page),
  );
};

interface InfiniteExperienceQueryResults extends RecommendedExperienceResponse {
  nextPage?: number;
}

export const useInfiniteExperiences = (tagGroupId?: number, fetchAutomatically: boolean = true) =>
  useInfiniteQuery<InfiniteExperienceQueryResults>({
    queryKey: experiencesQueryKeys.infiniteRecommended,
    queryFn: ({ pageParam = 1 }) => getRecommendedExperiences({ pageParam, tagGroupId }),
    getNextPageParam: (currentPage) => {
      return currentPage.nextPage;
    },
    enabled: fetchAutomatically,
  });

export const useRateExperience = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ rating, audioId, listenerId }: { rating: number; audioId: number; listenerId: number }) => {
      return axiosPost(
        `/listener_audio/${audioId}/favorite`,
        {
          rating,
        },
        'v3',
      );
    },
    {
      onSuccess: (_, { listenerId }) => {
        queryClient.invalidateQueries(['peerDetail', listenerId]);
        queryClient.invalidateQueries(['textSearchExperiences']);
      },
      onMutate: ({ rating, listenerId, audioId }) => {
        // update all the search resposnses with the new rating
        queryClient.setQueryData<RecommendedExperienceResponse>(['textSearchExperiences'], (data) => {
          if (!data) {
            return;
          }
          return {
            count: data.count,
            data: {
              ...data.data,
              ...data.data.map((item) => {
                if (item.id !== audioId) {
                  return item;
                } else {
                  return {
                    ...item,
                    rating: rating,
                  };
                }
              }),
            },
          };
        });
        queryClient.setQueryData<PeerDetail>(['peerDetail', listenerId], (data) => {
          if (!data) {
            return;
          }

          return {
            ...data,
            listener_audio: [
              ...data.listener_audio.map((item) => {
                if (item.id !== audioId) {
                  return item;
                } else {
                  return {
                    ...item,
                    rating: rating,
                  };
                }
              }),
            ],
          };
        });
      },
    },
  );

  return {
    ...mutation,
  };
};
