import React from 'react';

import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { PeerResponse } from '../../../hooks/usePeers';
import { UseQueryResult } from '@tanstack/react-query';
import {
  MyConnectionsPeerTile,
  MyConnectionsPeerTileSkeleton,
} from '../../../components/mwa-3.5-redesign/my-connections-peer-tile';

interface MakeConnectionsProps {
  top6Peers: UseQueryResult<PeerResponse, unknown>;
}

const MakeConnections: React.FC<MakeConnectionsProps> = ({ top6Peers }) => {
  return (
    <TileContainer
      title={'Make Connections'}
      subtitle={'Peers to connect with'}
      isLoading={top6Peers.isLoading}
      childrenTiles={
        <>{top6Peers?.data?.data?.map((peer) => <MyConnectionsPeerTile key={peer.listener_role_id} peer={peer} />)}</>
      }
      skeletonTiles={[1, 6].map((int) => (
        <MyConnectionsPeerTileSkeleton key={int} />
      ))}
      dataTestId={'my-connections-container'}
      titleSkeletons={top6Peers.isLoading}
    />
  );
};

export default MakeConnections;
