import { CircledArrowRightIcon } from '@kindlyhuman/component-library';
import useAuth from '../../hooks/useAuth';

export const LoginButton: React.FC = () => {
  const { keycloak, redirectTo } = useAuth();

  return (
    <div className="flex justify-center items-center gap-2">
      <div className="text-gray-800 text-sm font-normal leading-tight">Already have an account?</div>
      <div
        data-testid="login-button"
        className="flex items-center gap-1 text-primary text-sm font-bold leading-tight cursor-pointer"
        onClick={() =>
          keycloak?.login({
            redirectUri: `${window.location.origin}${redirectTo}`,
          })
        }
      >
        Log in
        <CircledArrowRightIcon />
      </div>
    </div>
  );
};
