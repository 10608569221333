import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { PartialMember, getPartialMember, useSendKeycloakVerificationLink } from '../../hooks/useUser';
import { ROUTE_PATH } from '../../routes/route-paths';
import { Button, CircledArrowRightIcon, MobileHeader } from '@kindlyhuman/component-library';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import Toast from '../../components/common/PopUpMessage';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import './verification.css';
import { LoginButton } from '../../components/common/login-button';

export const VerificationPage: React.FunctionComponent = (): JSX.Element => {
  const navigate = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const emailAddress = queryParams.get('email_address');
  const mode = queryParams.get('mode');
  const sendKeycloakVerificationLink = useSendKeycloakVerificationLink();
  const dfMdMedia = useMediaQuery('md');

  const [partialMember, setPartialMember] = useState<PartialMember | undefined>(undefined);
  const [sentLink, setLinkSent] = useState(false);

  const isWelcome = mode === 'welcome';

  const sendVerificationLink = (type: 'text' | 'email', partialMember?: PartialMember, isResend = false) => {
    if (!partialMember) {
      return;
    }
    sendKeycloakVerificationLink.mutate(
      {
        userId: partialMember.user_id,
      },
      {
        onSuccess: () => {
          if (isResend) {
            Toast.success('Verification link sent successfully.');
            setLinkSent(true);
          }
        },
        onError: (err: any) => {
          Toast.error(err.response.data.description);
        },
      },
    );
  };

  useEffect(() => {
    getPartialMember(
      { user_id: JSON.parse(sessionStorage.getItem('partial_member_id')!) },
      (partialMember) => {
        setPartialMember(partialMember);
        sendVerificationLink('email', partialMember);
      },
      (err) => {
        console.error(err);
        Toast.error("That didn't work. please try again.");
        navigate.push(ROUTE_PATH.SIGNUP);
      },
    );
  }, [navigate]);

  // we don't want a situation where the user can access this page without an email address, like navigating straight to it
  // TODO: CLJ EB MAYBE this should be handled by the router
  if (!emailAddress) {
    return <Redirect to={ROUTE_PATH.SIGNUP} />;
  }

  return (
    <EmailLinkVerification
      navigate={navigate}
      sentLink={sentLink}
      dfMdMedia={dfMdMedia}
      sendVerificationLink={sendVerificationLink}
      partialMember={partialMember}
      isWelcome={isWelcome}
    />
  );
};

interface EmailVerificationProps {
  navigate: any;
  sentLink: boolean;
  dfMdMedia: boolean;
  sendVerificationLink: (type: 'text' | 'email', partialMember?: PartialMember, isResend?: boolean) => void;
  partialMember: PartialMember | undefined;
  isWelcome: boolean;
}

const EmailLinkVerification = ({
  navigate,
  sentLink,
  dfMdMedia,
  sendVerificationLink,
  partialMember,
  isWelcome,
}: EmailVerificationProps) => {
  return (
    <>
      <MobileHeader className="md:hidden" onBack={() => navigate.goBack()} />
      <div className="grid w-screen grid-cols-1 md:grid-cols-2 md:h-screen">
        <WelcomeSlogan className="hidden md:block" />
        <div className="md:bg-whiteSmoke md:flex md:items-center md:justify-center">
          <div className="px-7.5 mt-8 pb-20 max-w-[480px] mx-auto">
            {sentLink ? (
              <>
                <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                  Please check your inbox
                </p>
                <h6 className="text-dark text-lg pt-8 leading-6">A new invite link has been sent.</h6>
                <div className="w-full flex justify-center pt-8 pb-4">
                  <Button
                    data-testid="close-button"
                    variant="primary"
                    onClick={() => {
                      navigate.push(ROUTE_PATH.LOGIN);
                    }}
                  >
                    {dfMdMedia ? 'Login' : 'Close'}
                  </Button>
                </div>
                <div className="w-full flex flex-col items-center gap-4 py-4">
                  <p className="text-sm text-center">
                    Didn't get an email? <br className="md:hidden" />
                    Click the button below to resend
                  </p>
                  <Button
                    data-testid="resend-email-button"
                    variant="primary"
                    onClick={() => {
                      sendVerificationLink('email', partialMember, true);
                    }}
                  >
                    Resend Link to my Email
                  </Button>
                </div>
              </>
            ) : (
              <>
                <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                  {isWelcome ? 'Welcome!' : "Hmm... that didn't work."}
                </p>
                <h6 className="text-dark text-lg pt-4 leading-6 pb-8">
                  {isWelcome
                    ? 'We need to verify your email. We just sent you an email - please open it and click the link to verify your account.'
                    : 'It looks like the verification code is incorrect or may be expired. We can resend you a new link by email or text'}
                </h6>
                <div className="w-full flex flex-col items-center gap-4">
                  {isWelcome && (
                    <p className="text-sm text-center">
                      Didn't get an email? <br className="md:hidden" />
                      Click the button below to resend
                    </p>
                  )}
                  <Button
                    data-testid="resend-email-button"
                    variant="primary"
                    onClick={() => {
                      sendVerificationLink('email', partialMember, true);
                    }}
                  >
                    Resend Link to my Email
                  </Button>
                </div>
                <h6 className="text-dark text-lg py-4 w-full flex justify-center">
                  {partialMember?.user_email_obfuscated}{' '}
                </h6>
              </>
            )}
            <div className="flex justify-center md:justify-between items-center gap-2 mt-4">
              <Link
                className="flex items-center gap-1 text-primary text-sm font-bold leading-tight"
                to={ROUTE_PATH.SIGNUP}
              >
                <CircledArrowRightIcon className="rotate-180" />
                Sign up
              </Link>
              <LoginButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
