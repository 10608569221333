import { axiosGetV3 } from '../api/axios-handler';
import { useQuery } from '@tanstack/react-query';
export interface TopSearch {
  id: number;
  client_ids: number[];
  query_text: string;
  created_at: string;
}

export interface TopSearchResponse {
  count: number;
  data: TopSearch[];
}

const getTopSearches = async (params: { client_id?: number; is_global?: boolean } = {}): Promise<TopSearchResponse> => {
  return await axiosGetV3('/search/top_searches', params).then((response) => response.data);
};

export const useClientTopSearches = (clientId: number) => {
  return useQuery<TopSearchResponse>(
    ['topSearches', 'client', clientId],
    () => getTopSearches({ client_id: clientId }),
    {
      refetchOnWindowFocus: false,
    },
  );
};

export const useGlobalTopSearches = () => {
  return useQuery<TopSearchResponse>(['topSearches', 'global'], () => getTopSearches({ is_global: true }), {
    refetchOnWindowFocus: false,
  });
};
