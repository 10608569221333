import React from 'react';
import { ROUTE_PATH } from '../../../routes/route-paths';

import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { AvailableListenersResponse } from '../../../hooks/usePeers';
import { UseQueryResult } from '@tanstack/react-query';
import {
  ConnectNowPeerTile,
  ConnectNowPeerTileSkeleton,
} from '../../../components/mwa-3.5-redesign/connect-now-peer-tile';
import { useHubspotEventMutation } from '../../../hooks/useEvent';
import useAuth from '../../../hooks/useAuth';

interface ConnectNowProps {
  availableListeners: UseQueryResult<AvailableListenersResponse, unknown>;
}

const ConnectNow: React.FC<ConnectNowProps> = ({ availableListeners }) => {
  const { user } = useAuth();
  const hubspotMutation = useHubspotEventMutation();
  return (
    <TileContainer
      title={'Connect Now'}
      subtitle={'Listeners available to connect right now'}
      isLoading={availableListeners.isLoading}
      childrenTiles={
        <>
          {availableListeners?.data?.data?.map((peer) => (
            <ConnectNowPeerTile key={peer.listener_role_id} peer={peer} />
          ))}
        </>
      }
      skeletonTiles={[1, 2].map((int) => (
        <ConnectNowPeerTileSkeleton key={int} />
      ))}
      bgColor={'bg-[#2ee5da] bg-opacity-40'}
      dataTestId={'connect-now-container'}
      redirectText={'See all available Listeners »'}
      redirectLink={ROUTE_PATH.AVAILABLE_LISTENERS}
      onClick={() => {
        hubspotMutation.mutate({
          event: 'click_see_available_listeners',
          properties: { client: user!.client_name, email_address: user!.email_address },
        });
      }}
      titleSkeletons={availableListeners.isLoading}
    />
  );
};

export default ConnectNow;
