import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { SearchFilterOptions } from '../../../hooks/useExperiences';
import { TopSearch, useGlobalTopSearches, useClientTopSearches } from '../../../hooks/useTopSearches';
import useAuth from '../../../hooks/useAuth';

interface TopSearchesProps {
  handleClose?: () => void;
  setSearchFilters: React.Dispatch<React.SetStateAction<SearchFilterOptions>>;
  handleSearch: (text?: string, filters?: SearchFilterOptions, searchLocation?: string) => void;
}

const TopSearchTile: React.FunctionComponent<{
  search: TopSearch;
  handleSearch: (text?: string, filters?: SearchFilterOptions, searchLocation?: string) => void;
}> = ({ search, handleSearch }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
    handleSearch(search.query_text, undefined, 'top-searches');
  };

  return (
    <div onClick={handleClick} className="py-3 px-4 rounded-[10px] bg-white cursor-pointer hover:bg-gray-50">
      <div className="text-sm font-texta">"{search.query_text}"</div>
    </div>
  );
};

export const TopSearches: React.FunctionComponent<TopSearchesProps> = ({ handleClose, handleSearch }) => {
  const { user } = useAuth();
  const { data: globalTopSearches } = useGlobalTopSearches();
  const { data: clientTopSearches } = useClientTopSearches(user?.client_id!);
  const topSearches =
    clientTopSearches?.data && clientTopSearches.data.length > 0 ? clientTopSearches.data : globalTopSearches?.data;
  const shuffledTopSearches = topSearches?.sort(() => Math.random() - 0.5);

  return (
    <TileContainer
      title="Top Searches"
      subtitle="Members like you are searching for support."
      bgColor={'bg-[#005DFE] bg-opacity-10'}
      childrenTiles={shuffledTopSearches
        ?.slice(0, 3)
        .map((topSearch) => <TopSearchTile handleSearch={handleSearch} search={topSearch} />)}
      handleClose={handleClose}
    />
  );
};
