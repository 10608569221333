import { HorizontalScrollSection } from '../../../components/common/horizontal-scroll';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import VimeoVideo, { VimeoVideoSkeleton } from '../../../components/mwa-3.5-redesign/vimeo-video';
import { Experience } from '../../../hooks/useExperiences';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

interface VideoCarouselProps {
  videoExperiences?: Experience[];
  isLoading: boolean;
}
const VideoCarousel: React.FC<VideoCarouselProps> = ({ videoExperiences, isLoading }) => {
  const dfMdMedia = useMediaQuery('md');
  return (
    <>
      <div className="mb-4 w-full max-w-7xl md:px-3">
        <TileContainer
          title="Voices of Experience"
          subtitle="Watch stories from people who have been there"
          childrenTiles={
            dfMdMedia ? (
              videoExperiences?.map((experience) => (
                <VimeoVideo
                  experience={experience}
                  playbackLocation={'video-carousel'}
                  showRating={false}
                  width={180}
                />
              ))
            ) : (
              <HorizontalScrollSection
                className=""
                showNavigationButton={false}
                isLoading={isLoading}
                children={
                  <>
                    <div></div>
                    {videoExperiences?.map((experience) => (
                      <VimeoVideo
                        experience={experience}
                        playbackLocation={'video-carousel'}
                        showRating={false}
                        width={150}
                      />
                    ))}
                    <div></div>
                  </>
                }
              />
            )
          }
          skeletonTiles={
            <HorizontalScrollSection
              className=""
              isLoading={isLoading}
              children={
                <>
                  <div></div>
                  {[...Array(4).keys()].map((int) => (
                    <VimeoVideoSkeleton key={int} />
                  ))}
                  <div></div>
                </>
              }
            />
          }
          isLoading={isLoading}
          bgColor=""
          horizontal
          videoContainer
        />
      </div>
    </>
  );
};

export default VideoCarousel;
