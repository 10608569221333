import React, { createContext, useEffect, useState, useRef } from 'react';
import Keycloak from 'keycloak-js';
import useStickyState, { StickyStateKeys } from '../hooks/useStickyState';
import { analytics, EventTypes } from '@kindlyhuman/component-library';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { axiosPost } from '../api/axios-handler';
import { Spinner } from '../components/common';
import { Authenticate, User, userQueryKeys, useUser } from '../hooks/useUser';
import { AxiosResponse } from 'axios';
import Toast from '../components/common/PopUpMessage';

interface AuthContextProps {
  keycloak: Keycloak | null;
  authToken: string | null;
  setAuthToken: React.Dispatch<string | null>;
  logout: () => void;
  user: User | null;
  isLoading: boolean;
  redirectTo: string | null;
  setRedirectTo: React.Dispatch<string | null>;
  authenticate: UseMutationResult<
    AxiosResponse<User, any>,
    unknown,
    Authenticate & {
      setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    },
    unknown
  >;
  authenticateWithToken: UseMutationResult<
    any,
    unknown,
    {
      token: string;
      userId: number;
    },
    unknown
  >;
  authenticateWithSSOToken: UseMutationResult<
    User,
    unknown,
    {
      token: string;
    },
    unknown
  >;
  getNewToken: (onExpired?: boolean) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_IDP_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_IDP_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_IDP_CLIENT_ID!,
  baseUrl: process.env.REACT_APP_BASE_URL!,
};

const TOKEN_EXPIRATION_THRESHOLD = 60; // seconds
const TOKEN_CHECK_INTERVAL = 30000; // milliseconds

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const isFirstRun = useRef<boolean>(false);
  const keycloak = useRef<Keycloak | null>(null);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(true);
  const [authToken, setAuthToken] = useStickyState(StickyStateKeys.AuthorizationToken, null);
  const { data: user, isFetched: tempUserIsLoading } = useUser(Boolean(authToken));
  const [redirectTo, setRedirectTo] = useState<string | null>(null);

  const isLoading = (authToken && !tempUserIsLoading) || !keycloak;

  const authenticate = useMutation(
    ({
      email_address,
      password,
      trusted,
    }: Authenticate & { setDisabled: React.Dispatch<React.SetStateAction<boolean>> }) => {
      return axiosPost(`/users/authenticate`, {
        email_address,
        password,
        trusted,
      }).then((response) => {
        analytics.trackEvent(EventTypes.LOGIN, 'WebApp');
        return response;
      });
    },
    {
      onSuccess: (res: AxiosResponse<User>, { setDisabled }) => {
        setAuthToken(res.data.authorization_token ?? null);
        queryClient.invalidateQueries(userQueryKeys.me);
      },
      onError: () => {
        Toast.error('Email and/or password is not correct.');
      },
      onSettled: (data, error, { setDisabled }) => {
        setDisabled(false);
      },
    },
  );

  const authenticateWithToken = useMutation(
    ({ token, userId }: { token: string; userId: number }) => {
      const data = {
        token,
      };
      return axiosPost(`/users/${userId}/authenticate_with_token`, data, 'v3');
    },
    {
      onSuccess: (result: any) => {
        setAuthToken(result.data.authorization_token ?? null);
        analytics.trackEvent(EventTypes.LOGIN, 'SmartLink');
        queryClient.invalidateQueries(userQueryKeys.me);
      },
    },
  );

  const authenticateWithSSOToken = useMutation(
    ({ token }: { token: string }) => {
      return axiosPost('/users/authenticate_with_sso_token', { token }, 'v3').then((response) => response.data);
    },
    {
      onSuccess: (result: User) => {
        setAuthToken(result?.authorization_token ?? null);
        analytics.trackEvent(EventTypes.LOGIN, 'SSO');
        queryClient.invalidateQueries(userQueryKeys.me);
      },
    },
  );

  const logout = async () => {
    setAuthToken(null);
    queryClient.removeQueries(['me']);
    axiosPost(`/users/deauthenticate`, {});
    if (keycloak) {
      keycloak.current?.logout();
    }
    analytics.trackEvent(EventTypes.LOGOUT);
    localStorage.clear();
  };

  const initKeycloak = async () => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 3000);

    const keycloakInstance: Keycloak = new Keycloak(keycloakConfig);

    keycloakInstance.onAuthSuccess = () => {
      setAuthToken(keycloakInstance.token ?? null);
    };

    keycloakInstance.onTokenExpired = () => {
      keycloakInstance
        .updateToken()
        .then((refreshed) => {
          if (refreshed) {
            console.log('Token refreshed by onTokenExpired event');
            queryClient.invalidateQueries(userQueryKeys.me);
            setAuthToken(keycloakInstance.token ?? null);
          }
        })
        .catch(() => {
          setAuthToken(null);
        });
    };

    keycloakInstance
      .init({
        onLoad: 'check-sso',
      })
      .then((authenticated: boolean) => {
        if (authenticated && keycloakInstance.token) {
          setAuthToken(keycloakInstance.token);
        }
      })
      .catch((error) => {
        console.error('Keycloak initialization failed:', error);
        setAuthToken(null);
      })
      .finally(() => {
        clearTimeout(timer);
        keycloak.current = keycloakInstance;
        setLoading(false);
      });
  };

  // Function to check and refresh the token
  const getNewToken = (onExpired = false) => {
    if (keycloak.current?.authenticated) {
      if (onExpired && !keycloak.current.isTokenExpired(TOKEN_EXPIRATION_THRESHOLD)) {
        return;
      }

      keycloak.current
        // we already know that the token is expired, so we can force a refresh
        .updateToken(0)
        .then((refreshed) => {
          if (refreshed) {
            setAuthToken(keycloak.current?.token ?? null);
          }
        })
        .catch(() => {
          setAuthToken(null);
        });
    }
  };

  useEffect(() => {
    if (isFirstRun.current) return;
    isFirstRun.current = true;
    initKeycloak();

    // Set up interval to check token expiration every 30 seconds
    const tokenCheckInterval = setInterval(() => getNewToken(true), TOKEN_CHECK_INTERVAL);

    // Clean up interval on component unmount
    return () => clearInterval(tokenCheckInterval);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider
      value={{
        keycloak: keycloak.current,
        authToken,
        setAuthToken,
        logout,
        user: (user as User) || null,
        isLoading,
        authenticate,
        authenticateWithToken,
        authenticateWithSSOToken,
        getNewToken,
        redirectTo,
        setRedirectTo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
