import React from 'react';
import { Spinner } from '../../../components/common';
import { ResourceDetailView } from '.././resource-detail';
import { CounselingDetailPng, PLUSVirtualBehavioralHealth } from '../../../assets';
import { DesktopMenuHeader, MobileMenuHeader } from '../../../components/MenuHeader';
import { useHistory } from 'react-router-dom';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';
import { ProviderDescription, useProviderDetails } from '../resource-components/useProviderDetails';

const Counseling: React.FunctionComponent = (): JSX.Element => {
  const navigate = useHistory();
  const {
    clientLogoUrl,
    isLoading,
    partnerResourceConfig,
    phoneNumber,
    providerName,
    ssoLinkLoading,
    url,
    urlDescription,
  } = useProviderDetails('counseling');
  const providerData = counselingProviderData[providerName!];

  if (isLoading) return <Spinner />;
  return (
    <div className="md:bg-neutral-700 md:bg-opacity-5 h-screen md:pb-8 flex flex-col">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={providerData.name}
        providerName={providerName}
        imageUrl={providerData.image}
        ssoLinkLoading={ssoLinkLoading}
        ctaButtonDisabled={providerName !== 'recuro' && ssoLinkLoading}
        ctaButtonText={'Connect Now'}
        url={url}
        urlDescription={urlDescription}
        phoneNumber={phoneNumber}
        tagNames={['Mental Health']}
        description={providerData.description}
        clientLogoUrl={clientLogoUrl}
        partnerResourceConfig={partnerResourceConfig}
      />
      <BackgroundStripe />
    </div>
  );
};

const counselingProviderData = {
  dialcare: {
    name: '+Counseling',
    description: (
      <ProviderDescription description="Through our partnership with Dialcare, you have access to licensed clinical counselors to discuss more serious issues." />
    ),
    image: CounselingDetailPng,
  },
  me_md: {
    name: '+Counseling',
    description: (
      <ProviderDescription description="Through our partnership with MeMD, you have access to licensed clinical counselors to discuss more serious issues." />
    ),
    image: CounselingDetailPng,
  },
  opyn: {
    name: '+Virtual Behavioral Health',
    description: (
      <ProviderDescription
        description="Your benefit includes virtual access for you and your family to connect with licensed counselors to assess your symptoms, evaluate your medical, psychiatric, and family history, and help decide on a course of action moving forward. You can also connect with a U.S. based board-certified Psychiatrist who can diagnose, treat, and prescribe medications for a range of mental health disorders, as necessary."
        commonUses={[
          'Grief and Loss',
          'Addiction',
          'Depression',
          'Anxiety',
          'Stress Management',
          'Bipolar Disorder',
          'Relationship Issues',
          'And more',
        ]}
        onlineAccessText="Click the link above to schedule a visit online."
      />
    ),
    image: PLUSVirtualBehavioralHealth,
  },
  recuro: {
    name: '+Virtual Behavioral Health',
    description: (
      <ProviderDescription
        description="Your benefit includes virtual access for you and your family to connect with licensed counselors to assess your symptoms, evaluate your medical, psychiatric, and family history, and help decide on a course of action moving forward. You can also connect with a U.S. based board-certified Psychiatrist who can diagnose, treat, and prescribe medications for a range of mental health disorders, as necessary."
        commonUses={['Grief and Loss', 'Addiction', 'Depression', 'Anxiety', 'Stress Management', 'And more']}
        onlineAccessText="Click the link above to schedule a visit online."
      />
    ),
    image: PLUSVirtualBehavioralHealth,
  },
};

export default Counseling;
