import { useCallback, useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';
import { useListenerAudioPlayMutation, useListenerAudioPlaybackMutation } from '../../hooks/useListenerAudio';
import { Experience, PlaybackLocation } from '../../hooks/useExperiences';
import { analytics, EventTypes } from '@kindlyhuman/component-library';
import { useEngagementEvent, useHubspotEventMutation } from '../../hooks/useEvent';
import useAuth from '../../hooks/useAuth';

export const useVimeoPlayer = (experience: Experience, playbackLocation: PlaybackLocation, width?: number) => {
  const { user } = useAuth();
  const playerRef = useRef<HTMLDivElement>(null);
  const vimeoPlayer = useRef<Player | null>(null);
  const playMutation = useListenerAudioPlayMutation();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const hubspotMutation = useHubspotEventMutation();
  const postEngagementEvent = useEngagementEvent();
  const [hasBeenPlayed, setHasBeenPlayed] = useState<boolean>(false);
  const currentPlaybackIdRef = useRef<number>();
  const trackedPercents = useRef<Set<number>>(new Set());
  const videoId = experience.video_id!;

  const handlePlay = useCallback(
    async (data: { percent: number }) => {
      trackedPercents.current.clear();
      if (hasBeenPlayed) return;
      setHasBeenPlayed(true);

      if (data.percent === 0) {
        trackedPercents.current.clear(); // Reset tracked percentages when starting from beginning
        hubspotMutation.mutate({
          event: 'play_experience',
          properties: {
            experience_id: experience.id,
            media_type: 'video',
            playback_location: playbackLocation || 'search-results',
          },
        });
        analytics.trackEvent(EventTypes.PLAY_EXPERIENCE, experience.id, 'video', playbackLocation);

        const duration = await vimeoPlayer.current?.getDuration();

        const playMutationResult = await playMutation.mutateAsync(
          {
            audioId: experience.id,
            listenerId: experience.listener_role_id,
          },
          {
            onSuccess: (listenerAudioPlayback) => {
              currentPlaybackIdRef.current = listenerAudioPlayback.id;
            },
          },
        );
        postEngagementEvent.mutate({
          engagementType: 'video_play',
          listenerAudioPlaybackId: playMutationResult.id,
          metaData: {
            user_id: user!.id,
            experience_id: experience.id,
            playback_location: playbackLocation || 'search-results',
            duration: duration || 0,
          },
        });
      }
    },
    [hasBeenPlayed, experience, playMutation],
  );

  const handleTimeUpdate = useCallback(
    (data: { percent: number }) => {
      if (!currentPlaybackIdRef.current) {
        console.error('currentPlaybackId is not set for experience id:', experience.id);
        return;
      }
      const percentage = Math.floor(data.percent * 100);
      const lastTimeUpdate = Math.floor(percentage / 10) * 10;

      if (!trackedPercents.current.has(lastTimeUpdate) && lastTimeUpdate <= 100) {
        trackedPercents.current.add(lastTimeUpdate);
        playBackMutation.mutate({
          audioId: currentPlaybackIdRef.current,
          percentCompleted: lastTimeUpdate,
        });
      }
    },
    [experience.id, playBackMutation],
  );

  useEffect(() => {
    if (playerRef.current && !vimeoPlayer.current) {
      vimeoPlayer.current = new Player(playerRef.current, {
        id: parseInt(videoId, 10),
        autoplay: false,
        controls: true,
        width: width || 336,
      });

      vimeoPlayer.current.on('play', handlePlay);
      vimeoPlayer.current.on('timeupdate', handleTimeUpdate);
      vimeoPlayer.current.on('error', (error) => {
        console.error('Vimeo Player Error:', error);
      });

      return () => {
        vimeoPlayer.current?.destroy();
        vimeoPlayer.current = null;
      };
    }
  }, [videoId]);

  return playerRef;
};
