import useAuth from '../../hooks/useAuth';
import { Spinner } from '../../components/common';

export const LoginPage = (): JSX.Element => {
  const { keycloak, redirectTo } = useAuth();

  keycloak?.login({
    redirectUri: `${window.location.origin}${redirectTo}`,
  });
  return <Spinner />;
};
