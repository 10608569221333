import React from 'react';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';

import { Experience, SearchFilterOptions } from '../../../hooks/useExperiences';
import { HorizontalScrollSection } from '../../../components/common/horizontal-scroll';
import { ExperienceTile, ExperienceTileSkeleton } from '../../../components/mwa-3.5-redesign/experience-tile';
import { ItemsNotFoundCTA } from '../../../components/mwa-3.5-redesign/items-not-found';

interface ExperienceRecommendationsProps {
  recommendedExperiences: Experience[] | undefined;
  isLoading: boolean;
  setSearchFilterOpen: React.Dispatch<React.SetStateAction<boolean>>;
  searchFilters: SearchFilterOptions;
  setSearchFilters: React.Dispatch<React.SetStateAction<SearchFilterOptions>>;
  handleScheduleModalOpen: (experience: Experience) => void;
  video?: boolean;
}

const ExperienceRecommendations: React.FC<ExperienceRecommendationsProps> = ({
  recommendedExperiences,
  isLoading,
  setSearchFilterOpen,
  searchFilters,
  setSearchFilters,
  handleScheduleModalOpen,
  video = false,
}) => {
  const hasResults = recommendedExperiences && recommendedExperiences.length === 0;
  return (
    <>
      <div className="mb-4 w-full max-w-7xl md:px-8">
        <TileContainer
          title="You're not alone"
          subtitle="Say hello to Peers with similar life experiences"
          childrenTiles={
            hasResults ? (
              <div></div>
            ) : (
              <HorizontalScrollSection
                className=""
                showNavigationButton={recommendedExperiences && recommendedExperiences.length > 3}
                isLoading={isLoading}
                children={recommendedExperiences?.map((experience: Experience) => (
                  <ExperienceTile
                    key={experience.id}
                    experience={experience}
                    handleScheduleModalOpen={handleScheduleModalOpen}
                  />
                ))}
              />
            )
          }
          skeletonTiles={
            <HorizontalScrollSection
              className=""
              showNavigationButton
              isLoading={isLoading}
              children={[...Array(6).keys()].map((int) => (
                <ExperienceTileSkeleton key={int} />
              ))}
            />
          }
          isLoading={isLoading}
          bgColor=""
          horizontal
          searchContainer
          setSearchFilterOpen={!video ? setSearchFilterOpen : undefined}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
        />
      </div>
      {hasResults && (
        <ItemsNotFoundCTA className="self-start pb-6">
          Your search & filters resulted in no matches. Broaden your search or update your{' '}
          <button
            onClick={() => setSearchFilterOpen(true)}
            className="bg-none border-none text-blue-500 underline cursor-pointer"
          >
            filters
          </button>{' '}
          for more results.
        </ItemsNotFoundCTA>
      )}
    </>
  );
};

export default ExperienceRecommendations;
