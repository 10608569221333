import { FilterIcon } from '@kindlyhuman/component-library';
import React from 'react';
import { Link } from 'react-router-dom';
import { ExitIcon } from '../common/svgs';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { SearchFilterOptions } from '../../hooks/useExperiences';
import { ALL_CONNECTIONS, useGetFilterLists } from '../search/search-filters-modal';

interface TileContainerProps {
  title: string;
  subtitle?: string;
  bgColor?: string;
  dataTestId?: string;
  redirectText?: string;
  redirectLink?: string;
  onClick?: () => void;
  isLoading?: boolean;
  skeletonTiles?: React.ReactNode;
  childrenTiles: React.ReactNode;
  clientLogo?: string;
  horizontal?: boolean;
  setSearchFilterOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  searchFilters?: SearchFilterOptions;
  setSearchFilters?: React.Dispatch<React.SetStateAction<SearchFilterOptions>>;
  searchContainer?: boolean;
  videoContainer?: boolean;
  transparent?: boolean;
  handleClose?: () => void;
  titleSkeletons?: boolean;
}

const TileContainer: React.FC<TileContainerProps> = ({
  title,
  subtitle,
  bgColor = 'bg-[#e6e6e6]',
  dataTestId,
  redirectText,
  redirectLink,
  onClick,
  isLoading,
  skeletonTiles,
  childrenTiles,
  clientLogo,
  horizontal,
  setSearchFilterOpen,
  searchFilters,
  setSearchFilters,
  searchContainer,
  videoContainer,
  transparent,
  handleClose,
  titleSkeletons,
}) => {
  const dfMdMedia = useMediaQuery('md');
  const filtersOn =
    (searchFilters?.peerType &&
      searchFilters?.peerType.length > 0 &&
      !(searchFilters?.peerType.length === 1 && searchFilters?.peerType.includes('All Connections'))) ||
    (searchFilters?.genderTags && searchFilters?.genderTags.length > 0) ||
    (searchFilters?.clientId && searchFilters?.clientId.length > 0) ||
    (searchFilters?.ageGroups && searchFilters?.ageGroups.length > 0) ||
    (searchFilters?.availableNow && searchFilters?.availableNow.length > 0);

  return (
    <div
      className={`${!searchContainer && !videoContainer && `w-90 min-w-90 h-full -z-10 ${!transparent && 'bg-white'} rounded-[10px]`}`}
    >
      <div
        data-testid={dataTestId}
        className={`h-full ${!horizontal && 'w-90 min-w-90'} ${bgColor} md:max-w-[1080px] rounded-[10px] flex flex-col relative select-none`}
      >
        {clientLogo && (
          <div className={`w-24 h-12 bg-white absolute right-4 top-0 flex justify-center`}>
            <img src={clientLogo} alt="" />
            <div
              className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-[40px] border-l-transparent border-r-[40px] border-r-transparent border-b-[6px] border-b-[${bgColor}] z-0`}
            />
          </div>
        )}
        {handleClose && (
          <ExitIcon
            data-testid="video-card-exit-icon"
            width={24}
            height={24}
            color={'#000'}
            className="cursor-pointer absolute right-4 top-6 flex justify-center"
            onClick={handleClose}
          />
        )}
        <div className={`flex ${searchContainer ? 'md:-mx-8' : 'px-3'} pt-2 justify-between`}>
          {titleSkeletons ? (
            <div className="flex flex-col animate-pulse w-full gap-2">
              <div className="bg-gray-300 h-8 w-1/2"></div>
              <div className="bg-gray-300 h-6 w-3/4"></div>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="text-2xl font-textaBlack leading-normal">{title}</div>
              {subtitle && <div className="text-sm font-thin opacity-70 font-texta leading-normal">{subtitle}</div>}
            </div>
          )}
          {setSearchFilterOpen && (
            <button
              data-testid="search-filter-button"
              onClick={() => setSearchFilterOpen(true)}
              className={`mt-2 h-10 w-10 flex justify-center items-center rounded-lg bg-[#240089]  ${filtersOn ? '' : 'bg-opacity-50'}`}
            >
              <FilterIcon color={'white'} className="h-6 w-6 cursor-pointer transform rotate-90" />
            </button>
          )}
        </div>
        {setSearchFilters && filtersOn && (
          <FilterPills searchFilters={searchFilters} setSearchFilters={setSearchFilters} filtersOn={filtersOn} />
        )}
        <div
          className={`flex py-2 gap-3
          ${!horizontal ? 'flex-col' : ''}
          ${searchContainer ? 'max-w-7xl md:px-6 -mx-2 md:mx-0' : ''}
          ${videoContainer ? `${isLoading || !dfMdMedia ? 'w-full' : 'w-90'} md:pl-3` : ''}
          ${!searchContainer && !videoContainer ? 'px-3' : ''}
          `}
        >
          {isLoading ? skeletonTiles : childrenTiles}
          {redirectLink && (
            <div className="flex justify-end py-3">
              <Link
                to={redirectLink}
                className="text-md font-medium font-textaMedium antialiased leading-normal"
                onClick={onClick || undefined}
              >
                {redirectText}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface FilterPillsProps {
  searchFilters: SearchFilterOptions;
  setSearchFilters: React.Dispatch<React.SetStateAction<SearchFilterOptions>>;
  filtersOn: boolean;
}

const FilterPills: React.FC<FilterPillsProps> = ({ searchFilters, setSearchFilters, filtersOn }) => {
  const dfMdMedia = useMediaQuery('md');
  const { availabilityList, ageGroupList, getClientList, getGenderList, peerTypeList } = useGetFilterLists();
  const clientList = getClientList();
  const genderList = getGenderList();

  const getFilterName = (id: string | number | undefined, type: string): string => {
    if (id === undefined || id === null) {
      return '';
    }

    let list = [];
    switch (type) {
      case 'peerType':
        list = peerTypeList;
        break;
      case 'genderTags':
        list = genderList!;
        break;
      case 'clientId':
        list = clientList;
        break;
      case 'ageGroups':
        list = ageGroupList;
        break;
      case 'availableNow':
        list = availabilityList;
        break;
      default:
        return id.toString();
    }
    const found = list.find((item) => item.id === id);
    return found ? found.name : id.toString();
  };

  const handleRemove = (type: string, filter?: string | number) => {
    const newFilters = { ...searchFilters };
    switch (type) {
      case 'peerType':
        const updatedPeerType = newFilters?.peerType?.filter((item: string) => item !== filter);
        newFilters.peerType = updatedPeerType?.length === 0 ? [ALL_CONNECTIONS] : updatedPeerType;
        break;
      case 'genderTags':
        newFilters.genderTags = newFilters?.genderTags?.filter((item: number) => item !== filter);
        break;
      case 'clientId':
        delete newFilters.clientId;
        break;
      case 'ageGroups':
        newFilters.ageGroups = newFilters?.ageGroups?.filter((item: string) => item !== filter);
        break;
      case 'availableNow':
        delete newFilters.availableNow;
        break;
      default:
        break;
    }
    setSearchFilters(newFilters);
  };

  return (
    filtersOn && (
      <div className={`flex flex-wrap gap-2 ${dfMdMedia && 'px-6'} pt-2`}>
        <>
          {searchFilters.availableNow && searchFilters.availableNow.length > 0 && (
            <div
              key={searchFilters.availableNow[0]}
              className="px-1 h-7 bg-purple-500 bg-opacity-30 border border-purple-500 border-opacity-50 rounded-full flex items-center justify-between"
            >
              <div className="p-2 text-sm font-thin font-texta">
                {getFilterName(searchFilters.availableNow[0], 'availableNow')}
              </div>
              <button
                className={`w-3 h-3 mr-1 ${!dfMdMedia && 'pb-0.5'} bg-white rounded-full text-xxs flex items-center justify-center`}
                onClick={() => {
                  handleRemove('availableNow');
                }}
              >
                x
              </button>
            </div>
          )}
          {searchFilters.peerType &&
            searchFilters.peerType.length > 0 &&
            searchFilters.peerType
              .filter((peerType) => peerType !== 'All Connections')
              .map((role) => (
                <div
                  key={role}
                  className="px-1 h-7 bg-purple-500 bg-opacity-30 border border-purple-500 border-opacity-50 rounded-full flex items-center justify-between"
                >
                  <div className="p-2 text-sm font-thin font-texta">{getFilterName(role, 'peerType')}</div>
                  <button
                    className={`w-3 h-3 mr-1 ${!dfMdMedia && 'pb-0.5'} bg-white rounded-full text-xxs flex items-center justify-center`}
                    onClick={() => {
                      handleRemove('peerType', role);
                    }}
                  >
                    x
                  </button>
                </div>
              ))}
          {Array.isArray(searchFilters.clientId) && searchFilters.clientId.length > 0 && (
            <div
              key={Array.isArray(searchFilters.clientId) ? searchFilters.clientId[0] : ''}
              className="px-1 h-7 bg-purple-500 bg-opacity-30 border border-purple-500 border-opacity-50 rounded-full flex items-center justify-between"
            >
              <div className="p-2 text-sm font-thin font-texta">
                {getFilterName(Array.isArray(searchFilters.clientId) ? searchFilters.clientId[0] : '', 'clientId')}
              </div>
              <button
                className={`w-3 h-3 mr-1 ${!dfMdMedia && 'pb-0.5'} bg-white rounded-full text-xxs flex items-center justify-center`}
                onClick={() => {
                  handleRemove('clientId');
                }}
              >
                x
              </button>
            </div>
          )}
          {searchFilters.ageGroups &&
            searchFilters.ageGroups.length > 0 &&
            searchFilters.ageGroups.map((ageGroup) => (
              <div
                key={ageGroup}
                className="px-1 h-7 bg-blue bg-opacity-20 border border-blue border-opacity-30 rounded-full flex items-center justify-between"
              >
                <div className="p-2 text-sm font-thin font-texta">{getFilterName(ageGroup, 'ageGroups')}</div>
                <button
                  className={`w-3 h-3 mr-1 ${!dfMdMedia && 'pb-0.5'} bg-white rounded-full text-xxs flex items-center justify-center`}
                  onClick={() => {
                    handleRemove('ageGroups', ageGroup);
                  }}
                >
                  x
                </button>
              </div>
            ))}
          {searchFilters.genderTags &&
            searchFilters.genderTags.length > 0 &&
            searchFilters.genderTags.map((genderTag) => (
              <div
                key={genderTag}
                className="px-1 h-7 bg-blue bg-opacity-20 border border-blue border-opacity-30 rounded-full flex items-center justify-between"
              >
                <div className="p-2 text-sm font-thin font-texta">{getFilterName(genderTag, 'genderTags')}</div>
                <button
                  className={`w-3 h-3 mr-1 ${!dfMdMedia && 'pb-0.5'} bg-white rounded-full text-xxs flex items-center justify-center`}
                  onClick={() => {
                    handleRemove('genderTags', genderTag);
                  }}
                >
                  x
                </button>
              </div>
            ))}
        </>
      </div>
    )
  );
};

export default TileContainer;
