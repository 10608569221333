import { useState, useRef, useEffect } from 'react';
import { useListenerAudioPlayMutation, useListenerAudioPlaybackMutation } from '../../hooks/useListenerAudio';
import { PlaybackLocation, useRateExperience, Experience } from '../../hooks/useExperiences';
import { PeerCardExperience } from '../../containers/peer-details/components/experiences-section';
import { analytics, EventTypes } from '@kindlyhuman/component-library';
import { useEngagementEvent, useHubspotEventMutation } from '../../hooks/useEvent';
import useAuth from '../../hooks/useAuth';

export const useAudioPlayer = (experience: Experience | PeerCardExperience, playbackLocation: PlaybackLocation) => {
  const { user } = useAuth();
  const audioRef = useRef<HTMLAudioElement>(null);
  const playMutation = useListenerAudioPlayMutation();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const hubspotMutation = useHubspotEventMutation();
  const rateExperienceMutation = useRateExperience();
  const postEngagementEvent = useEngagementEvent();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [paused, setPaused] = useState<boolean>(true);
  const [percent, setPercent] = useState<number>(0);
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const [experienceRating, setExperienceRating] = useState<number>(experience.rating);
  const trackedPercents = useRef<Set<number>>(new Set());

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  const onPlay = async () => {
    if (isPlaying) return;
    trackedPercents.current.clear();
    setIsPlaying(true);

    if (percent === 0) {
      hubspotMutation.mutate({
        event: 'play_experience',
        properties: {
          experience_id: experience.id,
          media_type: 'audio',
          playback_location: playbackLocation || 'search-results',
        },
      });
      analytics.trackEvent(EventTypes.PLAY_EXPERIENCE, experience.id, 'audio', playbackLocation);
      const playMutationResult = await playMutation.mutateAsync(
        {
          audioId: experience.id,
          listenerId: experience.listener_role_id,
        },
        {
          onSuccess: (listenerAudioPlayback) => {
            setCurrentPlaybackId(listenerAudioPlayback.id);
          },
        },
      );
      postEngagementEvent.mutate({
        engagementType: 'audio_play',
        listenerAudioPlaybackId: playMutationResult.id,
        metaData: {
          user_id: user!.id,
          experience_id: experience.id,
          playback_location: playbackLocation || 'search-results',
          duration: experience.duration,
        },
      });
    }
    if (audioRef.current) {
      audioRef.current.currentTime = (percent / 100) * audioRef.current.duration;
      audioRef.current.play();
    }
  };

  const onPause = () => {
    audioRef.current?.pause();
    setIsPlaying(false);
    setPaused(true);
  };
  const onTimeUpdate = (e: React.SyntheticEvent<HTMLAudioElement>) => {
    if (e.currentTarget.currentTime >= e.currentTarget.duration) {
      setPaused(true);
      setIsPlaying(false);
      e.currentTarget.currentTime = 0;
      setCurrentPlaybackId(0);
    }

    if (!currentPlaybackId) {
      console.error('currentPlaybackId is not set for experience id:', experience.id);
      return;
    }

    const percent = Math.round((e.currentTarget.currentTime / experience.duration) * 100);
    const lastTimeUpdate = Math.floor(percent / 10) * 10;

    // Track if we've crossed a 10% threshold
    if (!trackedPercents.current.has(lastTimeUpdate) && lastTimeUpdate <= 100) {
      trackedPercents.current.add(lastTimeUpdate);
      playBackMutation.mutate({
        audioId: currentPlaybackId,
        percentCompleted: lastTimeUpdate,
      });
    }
  };

  const handleRatingClick = (newRating: number) => {
    if (experienceRating === newRating) newRating = 0;

    setExperienceRating(newRating);

    rateExperienceMutation.mutate({
      audioId: experience.id,
      listenerId: experience.listener_role_id,
      rating: newRating,
    });
  };

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    const node = audioRef.current;

    const timingEvent = (event: Event) => {
      const element = event.target as HTMLAudioElement;
      setPercent(parseFloat(((element.currentTime / element.duration) * 100).toPrecision(3)));
    };

    node.addEventListener('timeupdate', timingEvent);

    return () => {
      node.removeEventListener('timeupdate', timingEvent);
    };
  }, []);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    setPaused(audioRef.current?.paused);
  }, [audioRef.current?.paused]);

  return {
    audioRef,
    paused,
    percent,
    experienceRating,
    audioStopAll,
    onPlay,
    onPause,
    onTimeUpdate,
    handleRatingClick,
  };
};
