import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { Button } from '@kindlyhuman/component-library';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import * as yup from 'yup';
import moment from 'moment';
import { User, useUser } from '../../../hooks/useUser';
import Name from './name';
import PhoneNumber from './phone-number';
import DOB from './dob';
import Toast from '../../../components/common/PopUpMessage';
import Intro from './intro';
import { ProfileFormData, mobileValid } from '../../../components/profile_form/profile-validation';
import { TimezoneOffsets, useAppSettings } from '../../../hooks/useAppSettings';
import useAuth from '../../../hooks/useAuth';

export const SYSTEM_DEFAULT_DATE = '01/01/0001';
export const INVALID_DATE = 'INVALID_DATE';

const createDefaultUser = (user?: User | null, timezoneOffsets?: TimezoneOffsets) => {
  // date_of_birth comes in the format YYYY-MM-DD and we test using the format DD/MM/YYYY
  const birthDates = user?.date_of_birth.split('-');
  const dateOfBirth = birthDates ? `${birthDates[1]}/${birthDates[2]}/${birthDates[0]}` : undefined;
  const localTimezoneGMTOffset = `${new Date()}`.match(/\sGMT(.{5})\s/)?.[1];
  const localTimezone =
    Object.keys(timezoneOffsets || {}).find(
      (timezoneKey) =>
        // @ts-ignore
        timezoneOffsets?.[timezoneKey] === localTimezoneGMTOffset,
    ) || 'US/Eastern';

  return {
    first_name: user?.first_name,
    last_name: user?.last_name,
    mobile_phone: user?.mobile_phone.replace('+1', ''),
    is_text_compatible_phone: user?.is_text_compatible_phone,
    timezone: timezoneOffsets
      ? // TODO: we need to remove the default timezone value when creating a user on the API side
        user?.mobile_phone
        ? user?.timezone
        : localTimezone
      : user?.timezone,
    date_of_birth: dateOfBirth,
    gender: user?.gender,
    pronoun: user?.pronoun,
    race: user?.race,
    relationship: user?.relationship,
    family: user?.family,
  };
};

const validationSchema = [
  //validation for step1
  yup.object({
    first_name: yup
      .string()
      .trim()
      .required('First Name is required.')
      .test({
        test: (value: string | undefined) => {
          return value ? value.length >= 2 : false;
        },
        exclusive: false,
        message: 'First Name must be 2 characters or more',
        name: 'Minimum Characters',
      }),
    last_name: yup
      .string()
      .trim()
      .required('Last Name is required.')
      .test({
        test: (value: string | undefined) => {
          return value ? value.length >= 2 : false;
        },
        exclusive: false,
        message: 'Last name must be 2 characters or more',
        name: 'Minimum Last Name Characters',
      }),
  }),
  //validation for step2
  yup.object({
    mobile_phone: yup
      .string()
      .trim()
      .required('Phone number is required')
      .matches(/^[0-9+() -]+$/, 'Phone number can only contain numbers.')
      .test('checkPhone', 'Not a valid phone number.', async (value?: string) => {
        try {
          if (value && value?.length >= 10) {
            return await mobileValid({ mobile_phone: value });
          } else {
            return false;
          }
        } catch (error) {
          return false;
        }
      }),
  }),
  //validation for step3
  yup.object({
    date_of_birth: yup
      .string()
      .required('Select date of birth')
      .test('Invalid DateTime', 'Invalid date format', (value?: string | undefined) => value !== INVALID_DATE)
      // here we need an additional field-is-required test to guard against the server default dob value 01-01-0001
      .test(
        'Date of birth is required',
        'Please enter your date of birth',
        (value?: string | undefined) => value !== SYSTEM_DEFAULT_DATE,
      )
      .test('Minimum Age', 'You must be at least 18 years old to use Kindly Human', (value?: string | undefined) => {
        const dob = moment(value, 'MM-DD-yyyy');
        const val = value ? moment().diff(dob, 'years') >= 18 : false;
        return val;
      }),
  }),
];

const ProfileStep: React.FC<{
  onComplete: () => void;
}> = ({ onComplete }) => {
  const { user } = useAuth();
  const { updateUser } = useUser();
  const { data: appSettings } = useAppSettings();

  const [step, setStep] = useState(0);
  const [profileStep, setProfileStep] = useState(false);

  const preferNotToSay = 'I prefer not to say';
  const introImg = user?.caller_role.active_subscription?.package?.client?.promo_configuration?.image_url;
  const introMessage = user?.caller_role.active_subscription?.package.client.landing_page_content;

  const methods = useForm({
    defaultValues: useMemo(() => {
      return createDefaultUser(user, appSettings?.timezone_offsets);
    }, [user, appSettings?.timezone_offsets]),
    resolver: yupResolver(validationSchema[step]),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    trigger,
    getValues,
    formState: { isValid, isValidating },
    reset,
  } = methods;

  useEffect(() => {
    reset(createDefaultUser(user, appSettings?.timezone_offsets));
  }, [user, reset, appSettings?.timezone_offsets]);

  const submit: SubmitHandler<Partial<ProfileFormData>> = async (data) => {
    data.gender = data.gender === preferNotToSay ? null : data.gender;
    data.pronoun = data.pronoun === preferNotToSay ? null : data.pronoun;
    data.race = data.race === preferNotToSay ? null : data.race;
    data.relationship = data.relationship === preferNotToSay ? null : data.relationship;
    data.family = data.family === preferNotToSay ? null : data.family;

    await updateUser.mutate(
      {
        first_name: data.first_name,
        last_name: data.last_name,
        date_of_birth: data.date_of_birth,
        mobile_phone: data.mobile_phone,
        is_text_compatible_phone: data.is_text_compatible_phone,
        timezone: data.timezone,
        gender: data.gender,
        pronoun: data.pronoun,
        race: data.race,
        relationship: data.relationship,
        family: data.family,
      },
      {
        onSuccess: () => {
          Toast.success('Profile updated successfully');
        },
      },
    );
    onComplete();
  };

  const handleNext = async (event: MouseEvent) => {
    // The button type has time to change while the event pops up
    event.preventDefault();

    const isStepValid = await trigger();

    if (isStepValid) {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
    reset({ ...getValues() }, { keepIsValid: true });
  };

  const renderStep = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return <Name />;
      case 1:
        return <PhoneNumber firstName={methods.getValues('first_name')} />;
      case 2:
        return <DOB />;
      default:
        return null;
    }
  };

  return (
    <>
      {!profileStep ? (
        <Intro introImg={introImg} introMessage={introMessage} goProfileStep={() => setProfileStep(true)} />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submit)}>
            {renderStep(step)}
            <div
              className="
                py-3 flex justify-evenly fixed bottom-0 left-0 w-full
                md:relative md:justify-between md:pt-8
              "
            >
              {step === 0 && (
                <Button
                  data-testid="back-button"
                  onClick={() => setProfileStep(false)}
                  type="button"
                  variant="secondary"
                >
                  <div className="w-30 md:w-auto">Back</div>
                </Button>
              )}
              {step > 0 && (
                <Button data-testid="back-button" onClick={handlePrevious} type="button" variant="secondary">
                  <div className="w-30 md:w-auto">Back</div>
                </Button>
              )}
              {step < 2 ? (
                <Button
                  data-testid="next-button"
                  onClick={handleNext}
                  type="button"
                  variant="primary"
                  disabled={!isValid}
                >
                  <div className="w-30 md:w-auto">Next</div>
                </Button>
              ) : (
                <Button
                  data-testid="submit-button"
                  variant="primary"
                  type="submit"
                  disabled={!isValid && !isValidating}
                >
                  <div className="w-30 md:w-auto">Submit</div>
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default ProfileStep;
