import { useMutation } from '@tanstack/react-query';
import { axiosPostV3 } from '../api/axios-handler';

export type HubspotEventType =
  | 'call_success'
  | 'click_call_icon'
  | 'click_client_resource'
  | 'click_client_resource_link'
  | 'click_client_resource_call_button'
  | 'click_connect_now_tile'
  | 'click_experience_tile'
  | 'click_global_resource'
  | 'click_global_resource_call_button'
  | 'click_global_resource_link'
  | 'click_provider'
  | 'click_provider_link'
  | 'click_provider_call_button'
  | 'click_see_available_listeners'
  | 'follow_peer'
  | 'login'
  | 'onboarding_completed'
  | 'onboarding_started'
  | 'play_client_video'
  | 'play_experience'
  | 'schedule_call'
  | 'submit_query';

export enum EventLocations {
  AVAILABLE_LISTENERS = 'available_listeners',
  CALL_CARDS = 'call_cards',
  MY_CONNECTIONS = 'my_connections',
  PEER_PROFILE = 'peer_profile',
  RECOMMENDED_PEERS = 'recommended_peers',
  SEARCH = 'search_results',
}

export interface HubspotEvent {
  event: HubspotEventType;
  properties?: {
    [key: string]: string | number | boolean;
  };
}

const postHobspotEvent = async (event: HubspotEvent) => {
  return axiosPostV3('/events/hubspot', event);
};

export const useHubspotEventMutation = () => {
  return useMutation(postHobspotEvent);
};

type EngagementEvents =
  | 'client_resource_view'
  | 'client_resource_impression'
  | 'global_resource_view'
  | 'global_resource_impression'
  | 'provider_view'
  | 'provider_impression'
  | 'video_play'
  | 'audio_play';
interface EngagementEventProps {
  engagementType: EngagementEvents;
  listenerAudioPlaybackId?: number;
  metaData: {
    [key: string]: string | number | boolean;
  };
}

const postEngagementEvent = async (params: EngagementEventProps) => {
  return axiosPostV3('/events/engagement', {
    engagement_type: params.engagementType,
    meta_data: params.metaData,
    listener_audio_playback_id: params.listenerAudioPlaybackId,
  });
};

export const useEngagementEvent = () => {
  return useMutation(postEngagementEvent);
};
