import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  UserIcon,
  CreditCardIcon,
  ExitIcon,
  InfoIcon,
  FileIcon,
  ShieldWithCheckIcon,
  Accordion,
  Dialog,
  DialogProps,
  SpinnerIcon,
} from '@kindlyhuman/component-library';
import { useLocation, useHistory } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { ProfileTab } from './components/profile_tab';
import { NotificationsTab } from './components/notifications_tab';
import { InviteDependentsTab } from './components/invite_dependents_tab';
import { MyPlanTab } from './components/my_plan_tab';
import { PaymentMethodTab } from './components/payment_method_tab';
import { CallHistoryTab } from './components/call_history_tab';
import { AboutUsTab } from './components/about_us_tab';
import { PrivacyPolicyTab } from './components/privacy_policy_tab';
import { TermsOfUseTab } from './components/terms_of_use_tab';
import { keycloakConfig } from '../../contexts/auth-context';
import Toast from '../common/PopUpMessage';
import useAuth from '../../hooks/useAuth';

export enum settingsModalAccordionItems {
  myProfile = 'My Profile',
  notifications = 'Notifications',
  inviteDependents = 'Invite Dependents',
  changePassword = 'Change Password',
  myPlan = 'My Plan',
  paymentMethod = 'Payment Method',
  callHistory = 'Call History',
  aboutUs = 'About us',
  privacyPolicy = 'Privacy Policy',
  termsOfUse = 'Terms of Use',
}
export interface SettingsModalProps extends DialogProps {
  initialItem?: settingsModalAccordionItems;
}

export const SettingsModal = ({ onClose, initialItem, ...rest }: SettingsModalProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const history = useHistory();
  const { user, isLoading: userIsLoading } = useAuth();
  const isMobile = useMediaQuery('md');

  const [selectedItem, setSelectedItem] = useState<settingsModalAccordionItems | undefined>(initialItem);
  const [nextSelectedItem, setNextSelectedItem] = useState<settingsModalAccordionItems | undefined>();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState<boolean>(false);
  const { url, realm, clientId, baseUrl } = keycloakConfig;

  const changeSelectedItem = useCallback(
    ({ text }: { text: string }) => {
      if (hasUnsavedChanges) {
        setShowUnsavedChangesModal(true);
        setNextSelectedItem(text as settingsModalAccordionItems);
      } else {
        setSelectedItem(text as settingsModalAccordionItems);
      }
    },
    [hasUnsavedChanges],
  );

  const onCloseModalTab = useCallback(() => {
    setShowUnsavedChangesModal(false);
    setHasUnsavedChanges(false);
    if (nextSelectedItem) {
      setSelectedItem(nextSelectedItem);
      setNextSelectedItem(undefined);
    } else {
      onClose();
    }
  }, [nextSelectedItem]);

  const onCloseModal = useCallback(() => {
    if (hasUnsavedChanges) {
      setShowUnsavedChangesModal(true);
    } else {
      onClose();
    }
  }, [hasUnsavedChanges, onClose]);

  const keycloakUpdatePassword = () => {
    const keycloakUrl = new URL(`${url}/realms/${realm}/protocol/openid-connect/auth`);
    keycloakUrl.searchParams.append('client_id', clientId);
    keycloakUrl.searchParams.append('redirect_uri', `${baseUrl}/home`);
    keycloakUrl.searchParams.append('response_type', 'code');
    keycloakUrl.searchParams.append('scope', 'openid');
    keycloakUrl.searchParams.append('kc_action', 'UPDATE_PASSWORD');

    window.location.href = keycloakUrl.toString();
  };

  const accordionItems = useMemo(
    () => [
      {
        text: 'Account Settings',
        icon: <UserIcon />,
        items: [
          {
            text: settingsModalAccordionItems.myProfile,
            onClick: changeSelectedItem,
          },
          {
            text: settingsModalAccordionItems.notifications,
            onClick: changeSelectedItem,
          },
          ...(user?.caller_role?.active_subscription?.package?.dependents_configuration?.enabled && !user.is_dependent
            ? [
                {
                  text: settingsModalAccordionItems.inviteDependents,
                  onClick: changeSelectedItem,
                },
              ]
            : []),
          {
            text: settingsModalAccordionItems.changePassword,
            onClick: changeSelectedItem,
          },
        ],
      },
      {
        text: 'Membership Management',
        icon: <CreditCardIcon />,
        items: [
          {
            text: settingsModalAccordionItems.myPlan,
            onClick: changeSelectedItem,
          },
          ...(!user?.onUnlimitedPlan
            ? [
                {
                  text: settingsModalAccordionItems.paymentMethod,
                  onClick: changeSelectedItem,
                },
              ]
            : []),
          {
            text: settingsModalAccordionItems.callHistory,
            onClick: changeSelectedItem,
          },
        ],
      },
    ],
    [user, changeSelectedItem],
  );

  useEffect(() => {
    if (!isMobile) {
      onClose();
    }
  }, [isMobile, onClose]);

  useEffect(() => {
    setSelectedItem(initialItem);
  }, [initialItem]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [selectedItem]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('kc_action_status') === 'success') {
      Toast.success('Password updated successfully!');
      history.replace({
        pathname: location.pathname,
        search: '',
      });
    }
  }, [location, history]);

  return (
    <Dialog
      className="rounded-lg w-full max-w-5xl h-150 shadow-modal p-0 bg-whiteSmoke backdrop:bg-modalBackdropColor"
      contentClassName="flex h-full"
      onClose={onCloseModal}
      {...rest}
    >
      {userIsLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <SpinnerIcon />
        </div>
      ) : (
        <>
          <div className="w-1/3 h-full flex flex-col justify-between p-6 bg-white border-r-2 border-neutral-200 overflow-auto">
            <div className="mb-10">
              <div className="flex justify-between items-center mb-10">
                <p className="text-2xl font-bold text-dark">Settings</p>
                <button className="" onClick={onCloseModal}>
                  <ExitIcon color="#22282F" />
                </button>
              </div>
              <Accordion items={accordionItems} selectedItemText={selectedItem} />
            </div>
            <div className="text-neutral-700">
              <button
                className="flex items-center mb-4"
                onClick={() => changeSelectedItem({ text: settingsModalAccordionItems.aboutUs })}
              >
                <InfoIcon width={16} height={16} color="#666666" className="ml-0.5 mr-2.5" />
                {settingsModalAccordionItems.aboutUs}
              </button>
              <button
                className="flex items-center mb-4"
                onClick={() => changeSelectedItem({ text: settingsModalAccordionItems.privacyPolicy })}
              >
                <ShieldWithCheckIcon width={20} height={20} color="#666666" className="mr-2" />
                {settingsModalAccordionItems.privacyPolicy}
              </button>
              <button
                className="flex items-center"
                onClick={() => changeSelectedItem({ text: settingsModalAccordionItems.termsOfUse })}
              >
                <FileIcon width={20} height={20} color="#666666" className="mr-2" />
                {settingsModalAccordionItems.termsOfUse}
              </button>
            </div>
          </div>
          <div
            ref={containerRef}
            className={`w-2/3 max-h-full overflow-auto ${selectedItem !== settingsModalAccordionItems.myProfile && 'p-6'}`}
          >
            {selectedItem === settingsModalAccordionItems.myProfile && (
              <ProfileTab
                showUnsavedChangesModal={showUnsavedChangesModal}
                onChange={() => setHasUnsavedChanges(true)}
                onCloseUnsavedChangesModal={() => setShowUnsavedChangesModal(false)}
                onDiscardUnsavedChangesModal={onCloseModalTab}
                onSuccessSubmitted={onCloseModalTab}
              />
            )}
            {selectedItem === settingsModalAccordionItems.notifications && <NotificationsTab />}
            {selectedItem === settingsModalAccordionItems.inviteDependents && <InviteDependentsTab />}
            {selectedItem === settingsModalAccordionItems.changePassword && <> {keycloakUpdatePassword()} </>}
            {selectedItem === settingsModalAccordionItems.myPlan && <MyPlanTab />}
            {selectedItem === settingsModalAccordionItems.paymentMethod && <PaymentMethodTab />}
            {selectedItem === settingsModalAccordionItems.callHistory && <CallHistoryTab />}
            {selectedItem === settingsModalAccordionItems.aboutUs && <AboutUsTab />}
            {selectedItem === settingsModalAccordionItems.privacyPolicy && <PrivacyPolicyTab />}
            {selectedItem === settingsModalAccordionItems.termsOfUse && <TermsOfUseTab />}
          </div>
        </>
      )}
    </Dialog>
  );
};
