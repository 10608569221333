import { QueryKey, UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPost } from '../api/axios-handler';
import { EventTypes, analytics } from '@kindlyhuman/component-library';
import useAuth from './useAuth';
import { useHubspotEventMutation } from './useEvent';

export const callQueryKeys = {
  callRequests: ['callRequests'] as const,
};

export interface Call {
  listener_role: CallListenerRole;
  listener_role_id: number;
  listener_state_id: any;
  logged_duration: number;
  package_id: any;
  pending_announcement: any;
  pending_announcement_all: any;
  phone_number: string;
  rating_caller_of_listener: any;
  recording_path: any;
  request: CallRequestData;
  state: any;
  state_id: any;
  stripe_payment_intent_id: any;
  termination_disposition: string;
  tier_id: number;
}

interface CallUser {
  display_name: string;
  first_name: string;
  id: number;
}

export interface CallListenerRole {
  id: number;
  profile_photo_file_url: string;
  profile_photo_square_file_url: string;
  user: CallUser;
}

export interface CallRequestData {
  audio_file_url: any;
  call_id: number;
  caller_role_id: number;
  connections_processed_at: any;
  created_at: string;
  id: number;
  is_backup_request: boolean;
  original_backup_request_id: any;
  original_request_id: any;
  package_id: any;
  pending_morphii_data: PendingMorphiiData;
  perform_backup_requests: boolean;
  status: string;
  tag_group_ids: number[];
  text: string;
  topic: Topic;
  topic_id: number;
  updated_at: any;
}

export interface PendingMorphiiData {
  morphii_display_name: string;
  morphii_id: string;
  morphii_intensity: number;
  morphii_part_name: string;
}

export interface Topic {
  audio_file_path: string;
  button_image_file_path: string;
  button_image_selected_file_path: string;
  description: string;
  id: number;
  identifier: string;
  is_default: boolean;
  is_featured: boolean;
  is_primary: boolean;
  is_required: boolean;
  morphii: string[];
  morphii_metadata: MorphiiMetadaum[];
  name: string;
  sample_1_audio_file_path: string;
  sample_1_theme: any;
  sample_2_audio_file_path: any;
  sample_2_theme: any;
  sort_weight: number;
  status: string;
  tag_id: number;
  theme: string;
}

export interface MorphiiMetadaum {
  delighted: Delighted;
  disappointed: Disappointed;
  id: string;
  label: string;
}

export interface Delighted {
  polarity: number;
}

export interface Disappointed {
  polarity: number;
}

export interface CallRequest {
  call_units_length: number;
  audio_duration: number;
  audio_file_url: string;
  call_id: number;
  caller_role_id: number;
  connections: Connection[];
  connections_processed_at: string;
  created_at: string;
  id: number;
  is_backup_request: boolean;
  original_backup_request_id: number;
  original_request_id: number;
  package_id: number;
  partner_code: string;
  pending_morphii_data: PendingMorphiiData;
  perform_backup_requests: boolean;
  status: 'new' | 'responded' | 'backup_pending' | 'backup_found';
  text: string;
  tag_group_ids?: number[];
  topic: RequestTopic;
  topic_id: number;
  updated_at: string;
}

export interface Connection {
  created_at: string;
  evaluation_ends_at: string;
  expired: boolean;
  id: number;
  listener_acknowledged: boolean;
  listener_role: ListenerRole;
  listener_role_id: number;
  offered_at: string;
  package_id: number;
  request_id: number;
  scheduled_at: string | null;
  status: 'matched' | 'passed' | 'offered' | 'accepted' | 'not_accepted' | 'canceled' | 'on_hold';
  tier_id: number;
  updated_at: string;
}

export interface ListenerRole {
  about_me: string;
  availabilities: Availability[];
  available: boolean;
  available_now: boolean;
  background_traits: string[];
  can_take_calls: boolean;
  connection_blocked: boolean;
  favorite_id: number;
  id: number;
  is_available: boolean;
  is_active: boolean;
  is_peer_active: boolean;
  listener_audio: ListenerAudio[];
  military_tag: string;
  next_available_text: string;
  peer_status: string;
  primary_language_tag: string;
  profile_photo_file_url: string;
  profile_photo_square_file_url: string;
  profile_traits: string[];
  secondary_language_tag: string;
  share_link_url: string;
  spirituality_tag: string;
  state: string;
  status: string;
  tier_id: number;
  type: string;
  user: User;
}

export interface Availability {
  day_of_week: string;
  end_date: string;
  ends_at: string;
  start_date: string;
  starts_at: string;
}

export interface ListenerAudio {
  created_at: string;
  duration: number;
  excerpt: string;
  file_url: string;
  first_approved_at?: string;
  id: number;
  is_favorite: boolean;
  last_updated_at: string;
  listener_role_id: number;
  message_quality: number;
  title: string;
  topic_tags: TopicTag[];
}

export type TagStatus = 'APPROVED' | 'REJECTED' | 'UNGRADED';

export interface TopicTag {
  created_at: string;
  id: number;
  status: TagStatus;
  listener_audio_id: number;
  media: string;
  postcall_metadata_id: number;
  sort_order: number;
  tag: Tag;
  tag_id: number;
  user_id: number;
}

export interface Tag {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  media?: TagMedia[];
  name: string;
  sort_order: number;
  tag_type: string;
}

export interface TagMedia {
  file_key: string;
  file_url: string;
}

export interface CallTopic {
  audio_file_url?: string;
  button_image_selected_url: string;
  button_image_url: string;
  description: string;
  id: number;
  identifier: string;
  is_default: boolean;
  is_featured: boolean;
  is_primary: boolean;
  is_required: boolean;
  morphii: string[];
  morphii_metadata: MorphiiMetadaum[];
  name: string;
  sample_1_audio_file_url?: string;
  sample_1_theme?: string;
  sample_2_audio_file_url: string;
  sample_2_theme: string;
  sort_weight: number;
  status: string;
  tag_id: number;
  theme: string;
}

export interface MorphiiMetadaum {
  delighted: Delighted;
  disappointed: Disappointed;
  id: string;
  label: string;
}

export interface Delighted {
  polarity: number;
}

export interface Disappointed {
  polarity: number;
}

export interface User {
  display_name: string;
  first_name: string;
  pronoun: string;
}

export interface PendingMorphiiData {
  mode: string;
  morphii_display_name: string;
  morphii_id: string;
  morphii_intensity: number;
  morphii_part_name: string;
}

export interface RequestTopic {
  audio_file_url: string;
  id: number;
  is_shadow_topic: boolean;
  morphii: string[];
  morphii_metadata: MorphiiMetadaum[];
  name: string;
  theme: string;
}

const getActiveCalls = async (callerRoleId: number): Promise<any> => {
  return await axiosGet(`/calls/?caller_role_id=${callerRoleId}&is_active=true`, {
    include_timezone_offset: true,
  }).then((response) => response.data);
};

const getAllCalls = async (callerRoleId: number): Promise<any> => {
  return await axiosGet(`/calls/?caller_role_id=${callerRoleId}`, {
    include_timezone_offset: true,
  }).then((response) => response.data);
};

const getCallRequests = async (callerRoleId: number): Promise<CallRequest[]> => {
  return await axiosGet(`/requests/?caller_role_id=${callerRoleId}&active=true`, {
    include_timezone_offset: true,
  }).then((response) => response.data);
};

const getCall = async (callId: number): Promise<Call> => {
  return await axiosGet(`/calls/${callId}`, {
    include_timezone_offset: true,
  }).then((response) => response.data);
};

export interface ActiveCall {
  call_rate: number;
  caller_charged_amount: any;
  caller_mobile_phone: string;
  caller_role: CallerRole;
  caller_role_id: number;
  caller_spent_amount: any;
  caller_state_id: number;
  collect_post_call_mood_metric: boolean;
  created_at: string;
  crisis_id: any;
  duration_timer_began_at: any;
  ended_at: any;
  id: number;
  listener_mobile_phone: string;
  listener_role: ListenerRole;
  listener_role_id: number;
  listener_state_id: number;
  logged_duration: number;
  package_id: number;
  partner_code: PartnerCode;
  pending_announcement: string;
  pending_announcement_all: any;
  phone_number: string;
  rating_caller_of_listener: any;
  recording_path: any;
  request: Request;
  state: State;
  state_id: number;
  stripe_payment_intent_id: any;
  termination_disposition: any;
  tier_id: number;
}

export interface CallerRole {
  id: number;
  user: User;
}

export interface PartnerCode {
  default_promo_audience_type: string;
  incentives_reward_image_url: any;
  incentives_reward_pto_image_url: any;
  is_default_promo: boolean;
  logo_file_path: string;
  logo_file_url: string;
  partner: Partner;
  promo_button_label: string;
  promo_image_file_path: string;
  promo_image_url: string;
  promo_placement: string[];
  promo_type: string;
  promo_url: string;
  promo_video_file_path: any;
  promo_video_thumbnail_file_path: string;
  promo_video_thumbnail_url: string;
  promo_video_url: any;
  url: string;
}

export interface Partner {
  comms_logo_file_url: any;
  logo_background_file_url: any;
  logo_file_path: string;
  logo_file_url: string;
  logo_overlay_file_url: any;
  name: string;
}

export interface Request {
  audio_file_url: any;
  call_id: number;
  caller_role_id: number;
  connections: Connection[];
  connections_processed_at: any;
  created_at: string;
  id: number;
  is_backup_request: boolean;
  original_backup_request_id: number;
  original_request_id: any;
  package_id: number;
  pending_morphii_data: PendingMorphiiData;
  perform_backup_requests: boolean;
  status: string;
  tag_group_ids: any[];
  text: any;
  topic: any;
  topic_id: any;
  updated_at: any;
}

export interface PendingMorphiiData {
  mode: string;
  morphii_display_name: string;
  morphii_id: string;
  morphii_intensity: number;
  morphii_part_name: string;
}

export interface State {
  id: number;
  sub_state_id: number;
  type: string;
}

export const useCall = (
  callId: number,
  options?: Omit<UseQueryOptions<Call, unknown, Call, QueryKey>, 'initialData' | 'queryFn' | 'queryKey'> & {
    initialData?: (() => undefined) | undefined;
  },
) => useQuery<Call>(['call', callId], () => getCall(callId), { ...options });

export const useActiveCalls = (callerRoleId?: number) => {
  const queryClient = useQueryClient();

  return useQuery<ActiveCall[]>(['activeCalls'], () => getActiveCalls(callerRoleId!), {
    enabled: !!callerRoleId,
    onSuccess: (activeCalls) => {
      if (activeCalls.length > 0) {
        const activeConnectionIds = activeCalls.flatMap((call) =>
          call.request.connections.map((connection) => connection.id),
        );
        queryClient.setQueryData<CallRequest[]>(callQueryKeys.callRequests, (oldData) => {
          return (
            oldData?.filter(
              (request) => !request.connections.some((connection) => activeConnectionIds.includes(connection.id)),
            ) || []
          );
        });
      }
    },
  });
};

// returns true if listener and member both available; else false; undefined if requests in flight
export const useIsEverybodyAvailable = (isPeerAvailable: boolean) => {
  const { user, isLoading: userIsLoading } = useAuth();
  const { data: activeCalls, isLoading: isCallsLoading } = useActiveCalls(user?.caller_role_id);
  if (userIsLoading || isCallsLoading) {
    return undefined;
  }
  const isMemberAvailable = !activeCalls?.length;
  return isMemberAvailable && isPeerAvailable;
};

export const useAllCalls = (callerRoleId?: number) =>
  useQuery<ActiveCall[]>(['allCalls'], () => getAllCalls(callerRoleId!), {
    enabled: !!callerRoleId,
  });

export const useCallRequests = (callerRoleId?: number) =>
  useQuery<CallRequest[]>(callQueryKeys.callRequests, () => getCallRequests(callerRoleId!), {
    enabled: !!callerRoleId,
  });

export interface CallRequestPayload {
  call_units_length: number | null;
  caller_role_id: number;
  pending_morphii_data?: {
    mode: 'pre';
    morphii_id: string;
    morphii_intensity: number;
    morphii_part_name: string;
    morphii_display_name: string;
  };
  text?: string;
  perform_backup_requests: boolean;
  original_request_id: number | null;
  scheduled_at?: string;
  tag_group_ids: number[];
}

export const useCallRequestMutation = () => {
  const { user } = useAuth();
  const hubspotMutation = useHubspotEventMutation();
  return useMutation(
    ({
      listenerRoleId,
      payload,
      location,
      peerName,
    }: {
      listenerRoleId: number;
      payload: CallRequestPayload;
      location: string;
      peerName: string;
    }) => {
      return axiosPost(`/requests/?listener_role_id=${listenerRoleId}`, payload).then((response) => ({
        response: response.data,
        metadata: { location, peerName, payload },
      }));
    },
    {
      onSuccess: ({ response, metadata: { location, peerName, payload } }) => {
        hubspotMutation.mutate({
          event: 'schedule_call',
          properties: {
            call_type: payload.scheduled_at ? 'scheduled' : 'now',
            client: user!.client_name,
            email_address: user!.email_address,
            location: location,
            peer_name: peerName,
            scheduled_at: payload.scheduled_at || 'now',
          },
        });
        analytics.trackEvent(
          EventTypes.SCHEDULE_CALL,
          payload.scheduled_at ? 'scheduled' : 'now',
          payload.tag_group_ids,
        );
        return response;
      },
    },
  );
};

export const useEndCallMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (callId: number) => {
      return axiosPost(`/calls/${callId}/end`, {}).then((response) => response.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['activeCalls']);
      },
    },
  );
};

export interface CreateCallRequestResponse {
  audio_duration?: number;
  audio_file_url: string;
  call_id: number;
  caller_role_id: number;
  connections_processed_at: number;
  created_at: string;
  id: number;
  is_backup_request: boolean;
  original_backup_request_id: number;
  original_request_id: number;
  package_id: number;
  partner_code: string;
  pending_morphii_data: PendingMorphiiData;
  perform_backup_requests: boolean;
  status: string;
  text: string;
  topic: CreateCallRequestTopic;
  topic_id: number;
  updated_at: string;
}

export interface PendingMorphiiData {
  mode: string;
  morphii_display_name: string;
  morphii_id: string;
  morphii_intensity: number;
  morphii_part_name: string;
}

export interface CreateCallRequestTopic {
  audio_file_url?: string;
  id: number;
  is_shadow_topic: boolean;
  morphii: string[];
  morphii_metadata: MorphiiMetadaum[];
  name: string;
  theme: string;
}

export interface MorphiiMetadaum {
  delighted: Delighted;
  disappointed: Disappointed;
  id: string;
  label: string;
}

export interface Delighted {
  polarity: number;
}

export interface Disappointed {
  polarity: number;
}

export const useRequestCancelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (requestId: number) => {
      return axiosPost(`/requests/${requestId}/cancel`, {});
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(callQueryKeys.callRequests);
      },
    },
  );
};

export const useAcceptBackupConnectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (connectionId: number) => {
      return axiosPost(`/connections/${connectionId}/connect`, {});
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(callQueryKeys.callRequests);
      },
    },
  );
};
