import React from 'react';
import { Provider, ErrorBoundary } from '@rollbar/react';

import { getRollBarEnvironment } from './containers/rollbar';

import { KindlyHumanTextBlue } from './assets/index';

import Routes from './routes';

import './styles/main.css';
import useScript, { ScriptEnvironments } from './hooks/useScript';
import useAuth from './hooks/useAuth';

declare global {
  interface Window {
    MorphiiWidgets: any;
  }
}

// We want to load this in early so the collection shows up faster
export const morphiiContext = new window.MorphiiWidgets.Collection();

const App: React.FunctionComponent = (): JSX.Element => {
  useScript({
    url: '//js.hs-scripts.com/45646789.js',
    id: 'hs-script-loader',
    environments: [ScriptEnvironments.PRODUCTION],
    crossOrigin: undefined,
  });
  /*
   It's a little easier to just inject the script hotjar provides here.
   we could just build the url, but this script is also pushing items to window._hjSettings
   I can see this being changed in the future, so it might be easier to copy paste this way
  */
  useScript({
    functionText:
      "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3927454,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script'); r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');",
    id: 'hotjar-script-loader',
    environments: [ScriptEnvironments.PRODUCTION],
    async: true,
    defer: false,
    crossOrigin: '',
    type: null,
    addToHead: true,
  });
  // const history = useHistory();
  // const { pathname } = useLocation();

  // const [showActiveCallModal, setShowActiveCallModal] = useState<boolean>(false);
  // const [shownActiveCall, setShownActiveCall] = useState<(number | undefined)[]>([]);

  // const { data: activeCalls } = useActiveCalls(useUser()?.data?.caller_role_id);
  // const dfMdMedia = 'md';

  const { user } = useAuth();
  const showFooter = user?.caller_role?.active_subscription?.package?.client?.powered_by_header_enabled;

  const rollbarConfig =
    window.location.origin.indexOf('localhost') !== -1
      ? {}
      : {
          accessToken: process.env.REACT_APP_ROLL_BAR_ACCESS_TOKEN,
          environment: getRollBarEnvironment(),
          code_version: '2.3.0',
        };

  /**
   * This useEffect defines situations when the user needs to show information about an active call
   * If the user uses a desktop, then a modal with information about an active call will be displayed
   * If the user uses mobile, then the user will be redirected to a page with information about an active call
   * Each of these actions is performed only once per call
   * If the user switches from desktop to mobile and the modal with information about the active call is open,
   * the user will be redirected to the page with information about the active call
   */
  // useEffect(() => {
  //   if (activeCalls?.length && !shownActiveCall.includes(activeCalls?.at(-1)?.id)) {
  //     setShownActiveCall((value) => [...value, activeCalls?.at(-1)?.id]);
  //     if (dfMdMedia && pathname !== ROUTE_PATH.ActiveCall) {
  //       setShowActiveCallModal(true);
  //     } else {
  //       history.push(ROUTE_PATH.ActiveCall);
  //     }
  //   }
  //   if (!dfMdMedia && showActiveCallModal) {
  //     setShowActiveCallModal(false);
  //     history.push(ROUTE_PATH.ActiveCall);
  //   }
  // }, [activeCalls, dfMdMedia, history, pathname, shownActiveCall, showActiveCallModal]);

  return (
    <div className="App h-full">
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <Routes />
          {/* <ActiveCallModal
            open={showActiveCallModal}
            onClose={() => setShowActiveCallModal(false)}
            activeCall={activeCalls?.at(-1)}
          /> */}
        </ErrorBoundary>
      </Provider>
      {showFooter && (
        <footer className="flex justify-center items-center bg-gray-100 py-3 text-xs">
          {/* Let's make this text into a single image or just drop the image and use text + CSS, but for now this will resolve our build error */}
          <span aria-hidden="true">Powered by</span>
          <img alt="Powered by Kindly, Human" className="ml-1 h-5" src={KindlyHumanTextBlue} />
        </footer>
      )}
    </div>
  );
};

export default App;
