import React from 'react';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { settingsModalAccordionItems } from '../../../components/settings_modal';
import { useSettingsModal } from '../../../contexts/settings-modal';

type MyPlanProps = {
  myPlanImage: string;
  clientLogo?: string;
};

const MyPlan: React.FunctionComponent<MyPlanProps> = ({ myPlanImage, clientLogo }): JSX.Element => {
  const history = useHistory();
  const dfMdMedia = useMediaQuery('md');
  const { openSettingModal } = useSettingsModal();

  return (
    <TileContainer
      title="My Plan"
      subtitle="Learn more about what your plan offers"
      childrenTiles={
        <div className="relative flex-1 flex justify-center items-center">
          <div
            className="aspect-w-16 aspect-h-9 relative mt-1.5 mb-2.5"
            onClick={
              dfMdMedia
                ? () => openSettingModal(settingsModalAccordionItems.myPlan)
                : () => history.push(ROUTE_PATH.PLAN)
            }
          >
            {/* We could use better alt text here, dynamically per resource? */}
            <img className="w-full h-full rounded-[10px] object-cover cursor-pointer" src={myPlanImage} alt="My Plan" />
          </div>
        </div>
      }
    />
  );
};
export default MyPlan;
